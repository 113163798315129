import {$host} from "../http/axios";

export default class AutomationMethods {
    static getAutomation = async () => {
        const {data} = await $host.post(`/automation`);
        return data;
    }
    static updateAutomation = async (formDara) => {
        const {data} = await $host.post(`/automation/update`, formDara);
        return data;
    }
}