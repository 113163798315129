import React from 'react';
import {Empty, Flex, Input} from "antd";
import {observer} from "mobx-react-lite";
import AutomationMethods from "../methods/automation.methods";
import UpdateAutomationFormComponent from "./panel/updateAutomationForm.component";

const AutomationComponent = () => {

    const [data, setData] = React.useState({});

    const getData = React.useCallback(async ( ) => {
        return AutomationMethods.getAutomation()
    })

    React.useEffect(() => {
        getData().then(response => {
            setData(() => response);
        })
    }, []);

    console.log(data)

    return (
        <Flex justify="center" align="top" style={{height: 'calc(100vh - 148px)'}}>
            {
                Object.keys(data).length > 0
                ?
                    <UpdateAutomationFormComponent />
                :
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />

            }
        </Flex>
    );
};

export default observer(AutomationComponent);