import React from 'react';
import {Button, Card, Divider, Flex, Form, Input, notification, Spin} from "antd";
import T from "../common/t";
import ConfigMethods from "../../methods/config.methods";
import {toolbarOptions} from "../web/oneWeb.component";
import ReactQuill from "react-quill";
import AutomationMethods from "../../methods/automation.methods";

const UpdateAutomationFormComponent = () => {
    const [loadingForm, setLoadingForm]  =  React.useState(false);

    React.useEffect(() => {
        AutomationMethods.getAutomation().then(res => {
            form.setFieldsValue(res);
        })
    }, []);

    const [form] = Form.useForm();

    const validateMessages = {
        // eslint-disable-next-line
        required: '${label} ' + 'is required',
        types: {
            // eslint-disable-next-line
            door_timeDelta: '${label} ' + 'is not a valid email',
        },
    };

    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, message, errors) => {
        api[type]({
            message,
            description: errors[0],
        });
    };

    const updateAutomation  = (values)  =>  {
        setLoadingForm(true);
        const formData = new FormData();
        formData.append("door_timeDelta", values.door_timeDelta);
        AutomationMethods.updateAutomation(formData).then(res  => {
            openNotificationWithIcon('success', <T>Автоматизации успешно обновлены</T>, []);
        }).catch(error => {
            if (error.response.data.errors.length > 0){
                error.response.data.errors.map(err => {
                    openNotificationWithIcon('error', error.response.data.message, [err]);
                })
            } else {
                openNotificationWithIcon('error', error.response.data.message, [error.response.data.errors]);
            }
        })
            .finally(() => setLoadingForm(false));
    }

    return (
        <>
            {contextHolder}
            <Form
                name="update_authomation"
                style={{ width: '100%', padding: '1rem 0 1rem 1rem'}}
                form={form}
                multipart={"form-data"}
                layout="vertical"
                validateMessages={validateMessages}
                onFinish={updateAutomation}
            >

                {
                    loadingForm
                        ?
                        <Flex justify="center" align="center" style={{height: 'calc(100vh - 148px)'}}>
                            <Spin size="large" />
                        </Flex>
                        :
                        <Flex justify="space-between">
                            <Flex vertical gap={20} style={{width: '100%'}}>
                                <Form.Item
                                    hasFeedback
                                    label={<T>Задержка закрытия дверей / сек</T>}
                                    name="door_timeDelta"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input size={"large"} />
                                </Form.Item>
                            </Flex>
                            <Flex vertical style={{width: '20%'}}>
                                <Flex vertical style={{width: '100%', position: 'sticky', top: '1rem'}}>
                                    <Form.Item shouldUpdate>
                                        {
                                            () => (
                                                <Card style={{boxShadow: 'none'}} bordered={false}>
                                                    <Button
                                                        size={"large"}
                                                        loading={false}
                                                        type="primary"
                                                        htmlType="submit"
                                                        style={{width: '100%'}}
                                                    >
                                                        <T>Сохранить</T>
                                                    </Button>
                                                </Card>
                                            )
                                        }
                                    </Form.Item>
                                </Flex>
                            </Flex>
                        </Flex>
                }
            </Form>
        </>
    );
};

export default UpdateAutomationFormComponent;